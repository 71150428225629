<template>
  <div class="content" :style="'background-image:url(' + require('@/assets/back.png') + ')'">
    <div class="logo" :style="'background-image: url(' + require('@/assets/logo.png') + ');'"></div>
    <div class="header" :style="'background-image: url(' + require('@/assets/header.png') + ');'"></div>
    <form class="form">
      <div v-if="!isLogin">
        <div class="inputs">
          <label>手机号码</label>
          <input placeholder="输入手机号码" v-model="phone" maxlength="11" />
        </div>
        <div class="inputs">
          <label>设置密码</label>
          <input placeholder="请设置账户密码" v-model="password" type="password" />
        </div>
        <div class="inputs">
          <label>确认密码</label>
          <input placeholder="请再次输入帐户密码" v-model="confirmation" type="password" />
        </div>
        <div class="inputs">
          <label>邀请码</label>
          <input placeholder="请输入邀请码" v-model="invitation" :disabled="isDisabled" />
        </div>
        <div class="inputs code">
          <label>验证码</label>
          <input placeholder="短信验证码" v-model="code" maxlength="4" />
          <button type="button" class="code-btn" :disabled="phone.length < 11 || codeState" @click="getCaptcha">{{codeText}}</button>
        </div>
        <button class="button" type="button" @click="bindUser">注册</button>
      </div>
      <div class="toast" v-else>
        <van-icon name="checked" size="60" color="#1f25ae" />
        <h4>账户已注册</h4>
        <p>点击下方按钮下载App</p>
      </div>
      <button class="button" type="button" @click="$router.push({name: 'App'})">下载App</button>
    </form>
    <!-- 弹出图形验证码 -->
    <van-popup v-model="showCaptcha" round closeable style="border-radius: 10px">
      <div class="captcha-content">
        <img :src="captchaSrc" class="captcha-img" @click="getCaptcha" />
        <input placeholder="请输入验证码" class="captcha-input" v-model="captchaVal">
        <button class="captcha-btn" type="button" @click="getCode()">验证</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { verifyCaptcha, captcha, register } from '@/interfaces/index'
  export default {
    data(){
      return {
        isDisabled  : false,
        captchaKey  : '',
        captchaSrc  : '',
        captchaVal  : '',
        showCaptcha : false,
        isLogin     : false,
        phone       : '',
        password    : '',
        confirmation: '',
        code        : '',
        invitation  : '',
        codeState   : false,
        codeText    : '获取验证码',
      }
    },
    mounted() {
      if(this.$route.query.invite_code){
        this.invitation = this.$route.query.invite_code
        this.isDisabled = true
      }
    },
    methods: {
      // 弹出图形验证码
      getCaptcha(){
        this.$toast.loading('加载中...')
        captcha().then(res => {
          let { img, key } = res;
          this.captchaKey = key
          this.captchaSrc = img
          this.showCaptcha = true
          this.$toast.clear()
        }).catch(err => {
          this.$toast(err.message)
        })
      },
      // 获取验证码
      getCode(){
        let getTime = 60;
        this.$toast.loading('加载中...')
        verifyCaptcha({
          mobileNo    : this.phone,
          captcha     : this.captchaVal,
          captcha_key : this.captchaKey,
          type        : 'register'
        }).then(res => {
          this.$toast(res.message)
          this.showCaptcha = false
          this.codeState = true
          this.codeText = getTime + 'S后重试';
          let CodeInterval = setInterval(() => {
            getTime--
            if(getTime > 0){
              this.codeText = getTime + 'S后重试';
              return
            }
            this.codeState = false
            this.codeText = '重新获取'
            clearInterval(CodeInterval)
          },1000)
        }).catch(err => {
          this.$toast(err.message)
        }).finally(()=> {
          this.captchaVal = ''
        })
      },
      // 登录
      bindUser(){
        this.$toast.loading('加载中...')
        register({
          username              : this.phone,
          password              : this.password,
          password_confirmation : this.confirmation,
          code                  : this.code,
          parent_id             : this.invitation
        }).then(res => {
          this.phone        = ''
          this.password     = ''
          this.confirmation = ''
          this.code         = ''
          this.invitation   = ''
          this.isLogin      = true
          this.$toast.clear()
        }).catch(err => {
          this.$toast(err.message)
        })
      }
    }
  }
</script>
<style scoped>
  .content{
    min-height: 100vh;
    background-position: center bottom;
    background-size: cover;
    padding: 30px;
    box-sizing: border-box;
  }
  .logo{
    width: 120px;
    height: 33px;
    background-size: cover;
  }
  .header{
    width: 80%;
    padding-top: 32%;
    background-size: cover;
    margin: 30px 10%;
  }
  .form{
    background: white;
    width: 90%;
    margin: 0 5%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
  }
  .inputs{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border-bottom: solid 1px #ddd;
  }
  .inputs:first-child{
    margin-top: 0;
  }
  .inputs label{
    width: 80px;
    font-size: 14px;
    color: gray;
    line-height: 40px;
  }
  .inputs input{
    height: 40px;
    line-height: 40px;
    border: none;
    width: calc(100% - 80px);
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .inputs.code > .code-btn{
    width: 90px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    border:none;
    height: 40px;
    line-height: 40px;
    background: transparent;
    color: #1f25ae;
  }
  .inputs.code > .code-btn[disabled]{
    opacity: .5;
  }

  .inputs.code > input{
    width: calc(100% - 170px);
    padding-right: 10px;
    box-sizing: border-box;
  }
  .button{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    background: #1f25ae;
    color: white;
  }
  /* 弹出验证码 */
  .captcha-content{
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
  }
  .captcha-img{
    vertical-align: top;
    width: 100px;
  }
  .captcha-input{
    height: 40px;
    border-radius: 5px;
    background: #f6f6f6;
    padding: 0 15px;
    text-align: center;
    border: none;
    margin-top: 15px;
    font-size: 14px;
  }
  .captcha-btn{
    height: 40px;
    background: #1f25ae;
    color: white;
    border-radius: 5px;
    width: 100%;
    line-height: 40px;
    margin: 15px 0 0 0;
    border: none;
    font-size: 14px;
    font-weight: bold;
  }
  /* 登录成功 */
  .toast{
    height: 300px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
    text-align: center;
  }
  .toast h4{
    font-size: 16px;
    color: #1f25ae;
    margin: 15px 0 0 0;
  }
  .toast p{
    margin: 0;
    font-size: 14px;
    color: gray;
    line-height: 40px;
  }
</style>


import http from '@/axios/index';

// 获取短信验证码
const verifyCaptcha = data => {
    return http.post('user/auth/verify_captcha', data)
}

// 图形验证码
const captcha = () => {
    return http.post('user/auth/captcha')
}

// 注册账号
const register = data => {
    return http.post('user/auth/register', data)
}

export {
    verifyCaptcha,
    captcha,
    register
}
